import {guestHost, useRuntimeConfig} from '#imports';
import type {TypedRoute} from '@typed-router';
import type {RouteLocationNormalizedLoaded} from 'vue-router';

export function extractQueryParameters(route: RouteLocationNormalizedLoaded | TypedRoute): string {
    const config = useRuntimeConfig();
    const baseURL = config.app.baseURL;
    let searchParams = new URL(`${guestHost()}${baseURL}${route.fullPath}`).search;
    if (searchParams.startsWith('?')) {
        searchParams = searchParams.substring(1);
    }

    return searchParams;
}
